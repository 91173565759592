var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.showLoading
    }
  }, _vm._l(_vm.messPromotions, function (promotion, promotionIndex) {
    return _c('div', {
      key: promotionIndex
    }, [_c('b-card', {
      class: "mb-1 cursor-pointer border-".concat(promotion !== null && promotion !== void 0 && promotion.isRead ? 'dark' : 'success', " hover-card shadow-md"),
      attrs: {
        "body-class": "px-1 py-50",
        "bg-variant": _vm.getVariantColor(promotion)
      }
    }, [_c('HStack', [!promotion.isRead ? _c('IAmIcon', {
      staticClass: "px-50",
      attrs: {
        "icon": "circle",
        "size": "14"
      }
    }) : _vm._e(), _c('Stack', {
      staticClass: "gap-2 flex-1"
    }, [_c('HStack', {
      attrs: {
        "between": ""
      }
    }, [_c('div', {
      staticClass: "pb-0"
    }, [_c('h3', {
      staticClass: "mb-0"
    }, [_c('span', {
      staticStyle: {
        "font-size": "large",
        "font-weight": "700"
      }
    }, [_vm._v(_vm._s(promotion.name))])])]), _c('div', {
      staticClass: "d-flex-center"
    }, [_c('span', {
      staticStyle: {
        "color": "black",
        "display": "flex",
        "gap": "3px"
      }
    }, [_vm._v(" " + _vm._s(_vm.dateTime(promotion.createdAt)) + " ")]), promotion.isPinPromotion === true ? _c('IAmIcon', {
      staticClass: "px-50",
      class: _vm.getVariantColorPin(promotion),
      attrs: {
        "icon": "pin",
        "size": "14"
      }
    }) : _vm._e()], 1)]), _c('HStack', {
      attrs: {
        "between": ""
      }
    }, [_c('div', {
      staticClass: "text-dark"
    }, [_c('div', {
      style: "".concat(!_vm.expandedItems[promotionIndex] ? 'max-height: 10rem; overflow: hidden; text-overflow: ellipsis;' : ''),
      domProps: {
        "innerHTML": _vm._s(_vm.resolveDescription(promotion.description, _vm.expandedItems[promotionIndex] ? 'expanded' : null))
      }
    })])]), _c('div', {
      staticClass: "d-flex justify-content-between fw-600"
    }, [_c('BLink', {
      staticClass: "text-success",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.toggleContent(promotionIndex);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("messBoard.".concat(_vm.expandedItems[promotionIndex] ? 'collapse' : 'viewMore'))) + " ")]), _vm.expandedItems[promotionIndex] ? _c('BLink', {
      staticClass: "ml-auto text-success",
      attrs: {
        "to": {
          name: 'apps-documents-edit',
          params: {
            id: promotion.id
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.detail')) + " ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }