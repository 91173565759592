<template>
  <IAmOverlay :loading="showLoading">
    <div
      v-for="(promotion, promotionIndex) in messPromotions"
      :key="promotionIndex"
    >
      <b-card
        body-class="px-1 py-50"
        :bg-variant="getVariantColor(promotion)"
        :class="`mb-1 cursor-pointer border-${promotion?.isRead ? 'dark' : 'success'} hover-card shadow-md`"
      >
        <HStack>
          <IAmIcon
            v-if="!promotion.isRead"
            icon="circle"
            size="14"
            class="px-50"
          />
          <Stack class="gap-2 flex-1">
            <HStack between>
              <div class="pb-0">
                <h3 class="mb-0">
                  <span style="font-size: large; font-weight: 700;">{{ promotion.name }}</span>
                </h3>
              </div>
              <div class="d-flex-center">
                <span style="color: black; display: flex; gap: 3px;">
                  {{ dateTime(promotion.createdAt) }}
                </span>
                <IAmIcon
                  v-if="promotion.isPinPromotion === true"
                  icon="pin"
                  size="14"
                  :class="getVariantColorPin(promotion)"
                  class="px-50"
                />
              </div>
            </HStack>

            <HStack between>
              <div class="text-dark">
                <div
                  :style="`${!expandedItems[promotionIndex] ? 'max-height: 10rem; overflow: hidden; text-overflow: ellipsis;' : ''}`"
                  v-html="resolveDescription(promotion.description, expandedItems[promotionIndex] ? 'expanded' : null)"
                />
              </div>
            </HStack>
            <div class="d-flex justify-content-between fw-600">
              <BLink
                class="text-success"
                @click.prevent="toggleContent(promotionIndex)"
              >
                {{ $t(`messBoard.${expandedItems[promotionIndex] ? 'collapse' : 'viewMore'}`) }}
              </BLink>
              <BLink
                v-if="expandedItems[promotionIndex] "
                class="ml-auto text-success"
                :to="{ name: 'apps-documents-edit', params: { id: promotion.id } }"
                target="_blank"
              >
                {{ $t('messBoard.detail') }}
              </BLink>
            </div>
          </Stack>
        </HStack>
      </b-card>
    </div>
  </IAmOverlay>
</template>
<script>
import {
  ref, onMounted, watch, set,
} from '@vue/composition-api'
import { BCard, BLink } from 'bootstrap-vue'

import api from '@/api'

import { dateTime } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BLink,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    promoMessageBoard: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const showLoading = ref(false)
    const expandedItems = ref([])
    const messPromotions = ref([])
    const getVariantColor = noti => (noti?.isRead ? 'light' : 'light-success')
    const getVariantColorPin = pro => (pro?.isRead && pro?.isPinPromotion ? 'text-success' : 'text-dark')
    const fetchPromotions = async () => {
      try {
        const res = await api.document.getPromotions()
        messPromotions.value = res.data.items
        emit('fetch-promo', messPromotions.value)
      } catch (error) {
        console.error('Lỗi khi lấy thông báo:', error)
      }
    }
    const resolveDescription = (description, expanded = false) => {
      if (!description) return description
      return description.replaceAll('<img ', `<img style="max-width: 100%; ${expanded ? '' : 'max-height: 60vh;'}"`)
    }
    onMounted(() => {
      fetchPromotions()
    })

    watch(
      () => props.promoMessageBoard,
      newItems => {
        if (!newItems.length) return
        try {
          showLoading.value = true
          messPromotions.value = props.promoMessageBoard.map(e => ({ ...e, isShow: false }))
        } finally {
          showLoading.value = false
        }
      },
      { deep: true, immediate: true },
    )
    watch(() => messPromotions.value, newItems => {
      if (!newItems.length) return
      expandedItems.value = newItems.map(item => item.isExpand || false)
    },
    { deep: true, immediate: true })

    const toggleContent = promotionIndex => {
      const item = messPromotions?.value[promotionIndex]
      if (!item) return
      set(item, 'isExpand', !item.isExpand)
      if (!item.isRead) {
        set(item, 'isRead', true)
      }
    }
    return {
      resolveDescription,
      showLoading,
      getVariantColor,
      messPromotions,
      getVariantColorPin,
      dateTime,
      toggleContent,
      expandedItems,

    }
  },
}
</script>

<style lang="scss" scoped>

.hover-card {
  &[class*="border-"]:not(:hover) {
    border-color: transparent !important;
  }
}
</style>
