var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "message-board"
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.showLoading
    }
  }, [_c('b-nav', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-nav-item', {
    attrs: {
      "to": "#note",
      "active": _vm.$route.hash === '#note' || !_vm.$route.hash
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "speaker",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.note')) + " ")]), _vm.boardsMessage.length ? _c('div', [_c('sub', {
    staticClass: "text-12px"
  }, [_vm._v(" (" + _vm._s(_vm.boardsMessage.length) + ") ")])]) : _vm._e()], 1)]), _c('b-nav-item', {
    attrs: {
      "lazy": "",
      "to": "#notification",
      "active": _vm.$route.hash === '#notification'
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "bellOutline",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('notification.title')) + " ")]), _vm.unReadCount ? _c('div', [_c('sub', {
    staticClass: "text-12px"
  }, [_vm._v(" (" + _vm._s(_vm.unReadCount) + ") ")])]) : _vm._e()], 1)]), _c('b-nav-item', {
    attrs: {
      "lazy": "",
      "to": "#promotion",
      "active": _vm.$route.hash === '#promotion'
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "promotionnal",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.promotion')) + " ")]), _vm.promotions.length ? _c('div', [_c('sub', {
    staticClass: "text-12px"
  }, [_vm._v(" (" + _vm._s(_vm.promotions.length) + ") ")])]) : _vm._e()], 1)]), _vm.$route.hash === '#note' ? _c('div', {
    staticClass: "d-flex flex-1 justify-content-end gap-2"
  }, [_c('span', {
    staticClass: "d-flex-center text-warning"
  }, [_vm._v(_vm._s(_vm.$t("messBoard.sort.".concat(_vm.sortOrder))))]), _c('b-button', {
    staticClass: "p-50",
    attrs: {
      "pill": "",
      "variant": "flat-warning"
    },
    on: {
      "click": _vm.sortMessages
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "sortOutline",
      "size": "22"
    }
  })], 1), _c('HStack', {
    staticClass: "flex-wrap",
    attrs: {
      "center": ""
    }
  }, _vm._l(_vm.filterValues, function (filterValue, filterValueIndex) {
    return _c('b-button', {
      key: filterValueIndex,
      staticClass: "min-width-120",
      attrs: {
        "pill": "",
        "size": "sm",
        "variant": _vm.selectedFilter === filterValue.value ? 'warning' : 'outline-dark'
      },
      on: {
        "click": function click($event) {
          _vm.selectedFilter = filterValue.value;
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("messBoard.priorityPin.".concat(filterValue.label))) + " ")]);
  }), 1)], 1) : _c('div', {
    staticClass: "d-flex-center justify-content-end gap-1 flex-1"
  }, [_vm.unReadCount && _vm.$route.hash === '#notification' ? _c('b-badge', {
    staticClass: "my-25 h-100 pl-75 mr-1 d-flex-center text-airline-warning",
    attrs: {
      "pill": "",
      "variant": "light-warning"
    }
  }, [_c('span', {
    staticClass: "text-airline-warning"
  }, [_vm._v(" " + _vm._s(_vm.unReadCount) + " " + _vm._s(_vm.$t('messBoard.unRead')) + " ")])]) : _vm._e(), _vm.$route.hash === '#notification' ? _c('b-button', {
    staticClass: "px-1 text-nowrap",
    attrs: {
      "rounded": "",
      "pill": "",
      "variant": "warning",
      "disabled": !_vm.unReadCount || _vm.showLoading
    },
    on: {
      "click": _vm.handleReadAllNoti
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.readAllMessages')) + " ")]) : _vm._e(), _c('b-form-input', {
    staticClass: " fit-content",
    staticStyle: {
      "min-width": "400px !important"
    },
    attrs: {
      "type": "search",
      "placeholder": _vm.$route.hash === '#notification' ? _vm.$t('messBoard.searchNameNoti') : _vm.$t('messBoard.searchNamePromotion'),
      "reduce": function reduce(val) {
        return val.value;
      },
      "debounce": "300",
      "trim": ""
    },
    model: {
      value: _vm.searchContentNoti,
      callback: function callback($$v) {
        _vm.searchContentNoti = $$v;
      },
      expression: "searchContentNoti"
    }
  })], 1)], 1), _c('div', {
    staticClass: "tab-content"
  }, [_c('div', {
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#note' || _vm.$route.hash === ''
    }]
  }, [_c('TabsNoteMessage', {
    attrs: {
      "messageBoard": _vm.filterMessage
    }
  })], 1), _c('div', {
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#notification'
    }]
  }, [_c('TabsNotificationMessage', {
    attrs: {
      "notiMessageBoard": _vm.filterNoti
    },
    on: {
      "fetch-noti": _vm.handleFetchNotication
    }
  })], 1), _c('div', {
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#promotion'
    }]
  }, [_c('TabsPromotions', {
    attrs: {
      "promoMessageBoard": _vm.filterPromotion
    },
    on: {
      "fetch-promo": _vm.handleFetchPromotions
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }